<template>
  <div id ="app">
      <Main_NavBar/>
      <router-view></router-view>
      <div v-if="$route.meta.showAds" ref="adContainer" class="ad-container" role="complementary" aria-label="広告"></div>
      <!--AdSection /-->
      <FootBar />
  </div>
</template>

<script lang="ts">
import { onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Main_NavBar from '@/components/Base/Main_NavBar.vue';
//import AdSection from '@/components/Base/AdSection.vue';
import FootBar from '@/components/Base/FootBar.vue';

// 広告管理用のカスタムフックを作成
const useAdsManager = (adContainer: any, route: any) => {
  const loadAds = () => {
    if (adContainer.value) {
      // AdSense スクリプトを動的に追加
      const adScript = document.createElement('script');
      adScript.async = true;
      adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4976359435956104';
      adScript.crossOrigin = 'anonymous';
      document.head.appendChild(adScript);

      // AdSense 広告要素を動的に挿入
      const adIns = document.createElement('ins');
      adIns.className = 'adsbygoogle';
      adIns.style.display = 'block';
      adIns.setAttribute('data-ad-client', 'ca-pub-4976359435956104');
      adIns.setAttribute('data-ad-slot', '6265851093');
      adIns.setAttribute('data-ad-format', 'auto');
      adIns.setAttribute('data-full-width-responsive', 'true');

      adContainer.value.appendChild(adIns);

      // AdSense 広告を読み込む
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  };

  const clearAds = () => {
    if (adContainer.value) {
      adContainer.value.innerHTML = '';
    }
  };

  onMounted(() => {
    if (route.meta.showAds) {
      loadAds();
    }
  });

  watch(() => route.meta.showAds, (showAds) => {
    if (showAds) {
      loadAds();
    } else {
      clearAds();
    }
  });

  onBeforeUnmount(() => {
    clearAds();
  });
};

export default {
  components: {
    Main_NavBar,
    //AdSection,
    FootBar,
  },
  setup() {
    const route = useRoute();
    const adContainer = ref<HTMLDivElement | null>(null);

    // 広告管理のカスタムフックを使用
    useAdsManager(adContainer, route);

    return {
      adContainer,
    };
  },
};
</script>

<style>
#app {
  font-family: 'Helvetica', sans-serif;
  color: #333;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: linear-gradient(135deg, #f5f5f5, #e0e0e0);
}

/* PC向けレイアウト */
@media (min-width: 769px) {
  #app {
    max-width: 1920px;
    min-width: 768px;
    margin: 0 auto;
    width: 100%;
  }
}

/* モバイル向けレイアウト */
@media (max-width: 768px) {
  #app {
    max-width: 100%;
  }
}

/* 広告コンテナのスタイル */
.ad-container {
  width: 100%;
  max-width: 728px;
  min-height: 90px;
  margin: 20px auto;
  text-align: center;
  display: block;
}

</style>


