<template>
  <div class="sentiment">
    <h1 class="main-title">感情分析くん</h1>

    <form @submit.prevent="analyze">
      <div class="form-item large-input">
        <label for="input" class="form-label">テストしたい文</label>
        <textarea id="input" v-model="form.input" autocomplete="off" placeholder="こちらに文章を入力してください。" style="font-size: 1.2rem;"></textarea>
      </div>
      <div class="form-footer">
        <button type="submit" :disabled="!form.input">解析</button>
      </div>

      <!-- ローディングスピナー -->
      <div class="loading" v-if="loading">
        <span class="loading-spinner" aria-live="polite" role="status"></span>
        <p>分析中</p>
      </div>

      <div class="form-item">
        <label class="form-label">↓分析結果が表示されます↓</label>
        <div class="readonly-text" placeholder="こちらに文章を入力してください。">{{ form.output }}</div>
      </div>
      
      <!-- エラーメッセージ -->
      <div v-if="errorMessage" class="error-message" role="alert" aria-live="assertive">
        <div class="alert" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </form>

  </div>
</template>

<script lang="ts">
import DataApiService from "@/service/DataApiService";
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const form = ref({
      input: '',
      output: '',
    });
    const errorMessage = ref('');
    const loading = ref(false); // ローディング状態を追加

    const analyze = async () => {
      loading.value = true; // ローディング開始

      try {
        const response = await DataApiService.create(form.value.input, form.value.output); // await を追加
        form.value.output = response.data.output;
      } catch (error) {
        console.error('Error:', error);
        errorMessage.value = '解析中にエラーが発生しました。もう一度更新してからお試しください。';
      } finally {
        loading.value = false; // ローディング終了
      }
    };

    return {
      form,
      analyze,
      errorMessage,
      loading
    };
  },
});
</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.main-title {
    font-size: 3em;
    margin-bottom: 20px;
}

.sentiment {
    max-width: 70%;
    padding: 10px 10px;
    text-align: center;
}

.form-item {
    width: 100%;
    font-size: 1.2rem;
    margin: 10px 0;
    /*border: 1px solid #ddd;*/
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.form-label {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.large-input textarea {
    width: 100%;
    height: 150px;
    margin-top: 10px;
    resize: vertical;
}

.readonly-text {
    font-size: 1.2rem;
    padding: 20px;
    border: 1.5px solid #ddd;
    border-radius: 10px;
    /*background-color: #f9f9f9;*/
    margin-top: 5px;
}

button[type="submit"] {
    background-color: #1eb300;
    border-color: #1eb300;
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button[type="submit"]:hover {
    background-color: #1eb300bf;
}

button[type="submit"]:disabled {
    background-color: #cccccc;
    border-color: #cccccc;
    cursor: not-allowed;
}

/*-------------------------------------------
ローディングスピナー
-------------------------------------------*/

.loading {
    margin-top: 30px;
}

.loading-spinner {
    border: 4px solid rgb(18, 136, 214);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/*-------------------------------------------
エラーハンドリング
-------------------------------------------*/
.error-message {
    margin-bottom: 10px;
    position: relative;
    margin:0px 5%;
}

.alert {
    padding: 15px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
    .main-title {
      font-size: 2em;
      white-space: nowrap; /* 1行に抑える */
    }
}

</style>
