<template>
    <div class="summarizer">
        <h1 class="main-title">文章要約くん</h1>

        <!-- ファイルアップロード -->
        <div class="file-container" aria-labelledby="file-upload-label">
            <label id="file-upload-label" class="fileinput-text">
                ドラッグ＆ドロップしてファイルをアップロード (.docx, .pdf)
            </label>
            <input ref="fileInput" type="file" accept=".pdf, .docx" class="large-input" @change="uploadFile" />
        </div>

        <!-- テキスト抽出ボタン -->
        <div v-if="fileUploaded && !textExtracted" class="button-container">
            <button @click="extractText" :disabled="loading" class="response-button extract-button">
                <span class="button-text">テキスト抽出</span>
            </button>
        </div>

        <!-- テキスト表示 -->
        <div v-if="extractedText" class="response-container">
            <div class="text-header">
                <h2>抽出されたテキスト</h2>
                <button @click="copyExtractedText" :disabled="loading" class="copy-text-button" aria-label="コピー">
                    {{ copySuccess ? 'コピーしました' : 'テキストをコピー' }}
                </button>
            </div>
            <p>{{ extractedText }}</p>
            <div class="button-container">
                <button @click="submitPrompt" :disabled="loading" class="response-button summarize-button">
                    <span class="button-text">要約</span>
                </button>
            </div>
        </div>

        <div class="loading">
            <span v-if="loading" class="loading-spinner"  aria-live="polite" role="status"></span>
            <p v-if="loading">要約中です。気長にお待ちください</p>
        </div>

        <div v-if="response" class="response-container">
            <h2>要約</h2>
            <p>{{ response }}</p>
            <div class="annotation-container">
                <p>この要約は自動生成されたものであり、原文の完全性や正確性を保証するものではありません。</p>
            </div>
        </div>

        <!-- エラーメッセージ -->
        <div v-if="errorMessage" class="error-message" aria-live="assertive">
            <div class="alert" role="alert">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// eslint-disable-next-line
import FileHandler from '@/service/FileHandler';
import { defineComponent } from 'vue';


export default defineComponent({
    data() {
        return {
        response: null as string | null,
        fileUploaded: false,
        textExtracted: false,
        extractedText: null as string | null,
        loading: false,
        copySuccess: false,
        errorMessage: '' as string
        };
    },
    methods: {
        // eslint-disable-next-line
        uploadFile(event: Event): void {
        const fileInput = this.$refs.fileInput as HTMLInputElement;
        const file = fileInput.files?.[0];
        if (file) {
            console.log('ファイルをアップロード:', file);
            this.fileUploaded = true;
            this.errorMessage = ''; // エラーメッセージをクリア
            this.extractedText = null;
            this.textExtracted = false;
        } else {
            this.errorMessage = 'ファイルが選択されていません。';
            console.error('ファイルが選択されていません。');
        }
        },

        async extractText(): Promise<void> {
            this.loading = true; // ボタンを押したらローディングを表示

            try {
                const fileInput = this.$refs.fileInput as HTMLInputElement;
                const file = fileInput.files?.[0];
                if (file) {
                    const text = await this.getFileHandler().extractText(file);
                    this.extractedText = text;
                    this.textExtracted = true;
                } else {
                    this.errorMessage = 'ファイルが選択されていません。';
                    console.error('ファイルが選択されていません。');
                }
            } catch (error) {
                this.errorMessage = 'エラーが発生しました。もう一度更新してからお試しください。';
            } finally {
                this.loading = false;
            }
        },        

        async submitPrompt(): Promise<void> {
            this.loading = true;

            try {
                const fileInput = this.$refs.fileInput as HTMLInputElement;
                const file = fileInput.files?.[0];
                if (file) {
                    const response = await this.getFileHandler().getAnswer(file);
                    this.response = response;
                } else {
                    this.errorMessage = 'ファイルが選択されていません。';
                    console.error('ファイルが選択されていません。');
                }
            } catch (error) {
                this.errorMessage = 'エラーが発生しました。もう一度更新してからお試しください。';
            } finally {
                this.loading = false;
            }
        },
            getFileHandler(): FileHandler {
            return new FileHandler();
        },
            copyToClipboard(text: string): void {
                const textArea = document.createElement('textarea');
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
                }, 2000);
        },

            copyExtractedText(): void {
                if (this.extractedText) {
                    this.copyToClipboard(this.extractedText);
                }
        },
            showCopySuccess() {
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
            }, 2000);
        },
    },
});
</script>


<style scoped>

/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.main-title {
    font-size: 3em;
    margin-bottom: 20px;
}


.summarizer {
    max-width: 70%;
    padding: 10px 10px;
    text-align: center;
}

.file-container {
    font-size: 1.2rem;
    margin-bottom: 10px;
    position: relative;
    /*border: 1px solid #ddd;*/
    border-radius: 10px;
    padding: 10px;
}

.response-container {
    font-size: 18px;
    margin-bottom: 20px;
    position: relative;
    border: 1.5px solid #ddd;
    border-radius: 10px;
    padding: 10px;
}

.response-container p {
    text-align: left;
}

.annotation-container {
    position: relative;
    padding: 20px;
    font-size: 10px;
}

.annotation-container p {
    text-align: center;
}

.button-container {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

/*-------------------------------------------
API入力・アップロードする画面
-------------------------------------------*/
.large-input {
    font-size: 1.2rem;
    padding: 8px;
    width: 80%;
}

/*-------------------------------------------
ボタン
-------------------------------------------*/
.response-button {
    font-size: 18px;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
    outline: none;
    position: relative;
    overflow: hidden;
    background-color: #007bff;
    color: white;
}

.response-button:hover {
    background-color: #0056b3;
}

.response-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.extract-button {
    background: linear-gradient(45deg, #007bff, #0056b3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.extract-button:hover {
    background: linear-gradient(45deg, #0056b3, #00408c);
}

.summarize-button {
    background: linear-gradient(45deg, #28a745, #218838);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.summarize-button:hover {
    background: linear-gradient(45deg, #218838, #1e7e34);
}

.text-header {
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
}

.copy-text-button {
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    background: #17a2b8;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.copy-text-button:hover {
    background: #117a8b;
}


.button-text {
    position: relative;
    font-size: 1.5em;
    z-index: 2;
}

/*-------------------------------------------
回答中のローディング画面
-------------------------------------------*/
.loading-spinner {
    border: 4px solid rgb(18, 136, 214);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/*-------------------------------------------
エラーハンドリング
-------------------------------------------*/
.error-message {
    margin-bottom: 10px;
    position: relative;
    margin:0px 5%;
}

.alert {
    padding: 15px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
    .main-title {
        font-size: 2em;
        white-space: nowrap; /* 1行に抑える */
    }

    .fileinput-text {
        font-size: 0.9rem;
    }

    .large-input {
        font-size: 0.9rem;
    }

    .button-text {
        position: relative;
        font-size: 1em;
        white-space: nowrap; /* 1行に抑える */
    }

    .response-container {
        font-size: 12px;
    }

    .response-container .annotation-container{
        font-size: 8px;
    }
}

</style>